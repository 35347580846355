import {Box, Theme, Typography, useMediaQuery} from "@mui/material";
import {Person} from "../types";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
  personsContainer: {
    flexFlow: "row wrap"
  },
  personCard: {
    width: "100%", 
    maxWidth: window.innerWidth <= 768 ? "100%" : "calc(33.3333% - 8px)",
    justifyContent: "flex-start", 
    gap: "12px", 
    alignItems: "center", 
    padding: "16px 12px 16px 16px", 
    marginBottom: "0"
  },
  personThumbnail: {
    borderRadius: "100%", 
    objectFit: "cover", 
    width: "100%", 
    maxWidth: "52px"
  },
  personRole: {
    fontSize: "11px", 
    lineHeight: "16px", 
    textOverflow: "ellipsis", 
    overflow: "hidden", 
    display: "-webkit-box", 
    WebkitLineClamp: "2", 
    WebkitBoxOrient: "vertical"
  },
  personName: {
    fontSize: "13px", 
    lineHeight: "20px"
  },
  cardText: {
    width: "100%"
  },
  phantomCard: {
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    width: "100%",
    maxWidth: window.innerWidth <= 768 ? "100%" : "calc(33.1111% - 8px)",
    borderRadius: "8px"
  }
})

export function Persons({persons, title}: { persons: Person[], title?: string }) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()

  if (!persons.length) return null

  return (

    <Box mb={isSm ? 6 : 8} className="scrollTo" id="persons">
      <Typography variant="h3" mb={3}>{title || "Key contacts"}</Typography>
      <Box display="flex" flexDirection={isSm ? "column" : undefined} gap={isSm ? "12px" : "16px"} className={classes.personsContainer}>
        {persons.map((p, i) => (
          <Box key={i} className={`card-container ${classes.personCard}`} style={{ flexBasis: isSm ? undefined : "32.1%" }}>
            <img src={p.avatar || "/images/default-logo.png"} width="52" height="52" alt={`${p.firstName} ${p.lastName}`} className={classes.personThumbnail} />
            <Box className={classes.cardText}>
              <Typography variant="h5" className={classes.personName}>{p.displayName}</Typography>
              {p.role && <Typography variant="subtitle1" className={classes.personRole}>{p.role}</Typography>}
            </Box>
            {p.linkedin && (
              <Box><a href={p.linkedin} target="_blank" rel="noreferrer" className="icon-button icon-linkedin" title="Connect on LinkedIn">LinkedIn</a></Box>
            )}
          </Box>
        ))}
        {isSm ? undefined : Array.from({ length: (3 - persons.length % 3) % 3 }).map((_, i) => (
          <div key={i} className={classes.phantomCard}></div>
        ))}
      </Box>
    </Box>

  )

}
