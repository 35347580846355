import {Box, Grid, Typography, Chip, Theme, useMediaQuery} from "@mui/material";
import {ResponsiveContainer} from "./responsive-container";
import {Link} from "react-router-dom";
import {buildExternalLink, setTitle} from "../helpers";
import {ReactNode, useEffect} from "react";
import {createUseStyles} from "react-jss";
import {NULL_VALUE} from "../constants";
import {formatDate} from "date-fns";
import {PageSection, StickyNav} from "./sticky-nav";
import {PaginatedResults} from "../api";
import {Persons} from "./persons";
import {Insights} from "./insights";
import {Specialties} from "./specialties";
import {Programs} from "./programs";
import {NewsArticle, Person, Program, Specialty, UploadedDocument} from "../types";
import {DocumentsSection} from "./documents-section";
import {InvestorsModule} from "./investors";
import {AlpineCompany} from "../pages/company/types";
import {ProductsModule} from "./products";
import {GenericSearchResult} from "./search-page";
import {SubsidiariesModule} from "./subsidiaries";

const useStyles = createUseStyles({
  closedChip: {
    background: "var(--black)",
    color: "var(--white)"
  }
})

export interface DataPoint {
  title: string
  value?: ReactNode
  hideEmpty?: boolean
}

interface Props {
  logo?: string
  name: string
  description?: string
  entityType: string
  intro: string
  url?: string
  linkedIn?: string
  crunchbase?: string
  twitter?: string
  instagram?: string
  facebook?: string
  tiktok?: string
  dataPoints: DataPoint[]
  children?: ReactNode
  closed?: string | undefined
}

export const usePageStyles = createUseStyles({
  sectionContainer: {
    maxWidth: "960px",
    margin: "auto"
  }
})

const INTRO_SECTION = {title: "Intro"}
const SUBSIDIARIES_SECTION = {title: "Subsidiaries", elementId: "subsidiaries"}
const PRODUCTS_SECTION = {title: "Products", elementId: "products"}
const PERSONS_SECTION = {title: "Contacts", elementId: "persons"}
const INSIGHTS_SECTION = {title: "Insights", elementId: "insights"}
const INVESTORS_SECTION = {title: "Investors", elementId: "investors"}
const PROGRAMS_SECTION = {title: "Programs", elementId: "programs"}

export function PageContent({entity, programs, programTitle, additionalSections, personsTitle, productsTitle, subsidiaryTitle}: {
  entity: { 
    persons: Person[], 
    news: NewsArticle[], 
    specialties?: Specialty[], 
    documents?: UploadedDocument[], 
    investorCompanies?: AlpineCompany[], 
    middlewares?: GenericSearchResult[], 
    subsidiaries?: AlpineCompany[]
  },
  programs: PaginatedResults<Program> | undefined,
  programTitle?: string,
  personsTitle?: string,
  productsTitle?: string,
  subsidiaryTitle?: string,
  additionalSections?: PageSection[]
}) {
  const pageStyles = usePageStyles()

  let pageSections: PageSection[] = [INTRO_SECTION]

  if (entity.subsidiaries?.length) {
    pageSections.push(SUBSIDIARIES_SECTION)
  }

  if (entity.middlewares?.length) {
    pageSections.push(PRODUCTS_SECTION)
  }

  if (entity.persons.length > 0) {
    pageSections.push(PERSONS_SECTION)
  }

  if (entity.news.length > 0) {
    pageSections.push(INSIGHTS_SECTION)
  }

  if ((entity.documents?.length || 0) > 0) {
    pageSections.push({title: "Documents", elementId: "documents"})
  }

  if (entity.investorCompanies?.length) {
    pageSections.push(INVESTORS_SECTION)
  }

  if (entity.specialties?.length) {
    pageSections.push({title: "Best Suited To", elementId: "specialties"})
  }

  if (additionalSections) {
    pageSections = pageSections.concat(additionalSections)
  }

  if ((programs?.results.length || 0) > 0) {
    pageSections.push({...PROGRAMS_SECTION, title: "Programs"})
  }

  return (

    <Box className="bg-alt">
      <StickyNav sections={pageSections} />
      <ResponsiveContainer includePadding className={pageStyles.sectionContainer}>
        <SubsidiariesModule title={subsidiaryTitle} subsidiaries={entity.subsidiaries} />
        <ProductsModule title={productsTitle} middlewares={entity.middlewares} />
        <Persons title={personsTitle} persons={entity.persons} />
        <Insights news={entity.news} />
        <DocumentsSection documents={entity.documents} />
        {entity.specialties && <Specialties specialties={entity.specialties} />}
        <InvestorsModule investorCompanies={entity.investorCompanies} />
        {pageSections.filter(s => s.section).map((s, i) => <Box key={i}>{s.section}</Box>)}
        {programs && <Programs programs={programs} title={programTitle || "Programs"} />}
      </ResponsiveContainer>
    </Box>

  )
}

export function PageHeader({logo, name, description, url, linkedIn, twitter, crunchbase, instagram, facebook, tiktok, dataPoints, children, closed }: Props) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const pageSyles = usePageStyles()
  const classes = useStyles()
  useEffect(() => {
    setTitle(name)
    document.body.style.background = "url(/images/bg-white.jpg)"
    document.body.style.backgroundSize = "150px 150px"
    return () => {
      setTitle()
      document.body.style.background = "unset"
      document.body.style.backgroundSize = "unset"
    }
  }, [name])

  const hasUrls = Boolean(url || linkedIn || crunchbase || twitter)
  const detailCol = {xs: 6, md: 3}

  return (

    <Box>
      <ResponsiveContainer includePadding className={pageSyles.sectionContainer} style={{ paddingTop: "40px" }}>
        <Box className={closed && "page-closed"}>
          <img className="logo-circle" src={logo || "/images/default-logo.png"} width="72" height="72" alt={name} style={{ display: "block" }} />
          <Typography variant="h1" mt={isSm ? 1.5 : 2}>{name}</Typography>
          {closed && <Chip label={`Closed: ${formatDate(new Date(closed), "MMM yyyy")}`} className={classes.closedChip} style={{ marginTop: "16px" }} />}
        </Box>
        <Typography variant="body2" mt={isSm ? 2 : 3}>{description}</Typography>
        {hasUrls && (
          <Box mt={1.5} display="flex" alignItems="center" gap="16px">
            {url && <a href={buildExternalLink(url)} target="_blank" rel="noreferrer" className="text-link" style={{ fontSize: "14px", lineHeight: "24px", letterSpacing: "normal" }}>View Website</a>}
            <Box display="flex" alignItems="center" gap="4px">
              {linkedIn && (
                <a href={linkedIn} target="_blank" className="icon-button icon-linkedin" title="View on LinkedIn" rel="noreferrer">LinkedIn</a>
              )}
              {crunchbase && (
                <a href={crunchbase} target="_blank" className="icon-button icon-crunchbase" title="View on Crunchbase" rel="noreferrer">Crunchbase</a>
              )}
              {twitter && (
                <a href={twitter} target="_blank" className="icon-button icon-x" title="View on X (Twitter)" rel="noreferrer">X (Twiiter)</a>
              )}
              {instagram && (
                <a href={instagram} target="_blank" className="icon-button icon-instagram" title="View on Instagram" rel="noreferrer">Instagram</a>
              )}
              {facebook && (
                <a href={facebook} target="_blank" className="icon-button icon-facebook" title="View on Facebook" rel="noreferrer">Facebook</a>
              )}
              {tiktok && (
                <a href={tiktok} target="_blank" className="icon-button icon-tiktok" title="View on TikTok" rel="noreferrer">TikTok</a>
              )}
            </Box>
          </Box>
        )}
        <Grid container mt={1.5} rowSpacing={2} columnSpacing={2}>
          {dataPoints.filter(dp => dp.value || !dp.hideEmpty).map(dp => (
            <Grid key={dp.title} item {...detailCol}>
              <Box>
                <Typography variant="h5">{dp.title}</Typography>
                <Typography variant="body2">{dp.value || NULL_VALUE}</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        {children}
      </ResponsiveContainer>
    </Box>

  )
}
