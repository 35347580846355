import {Box, Chip, Typography, Theme, useMediaQuery} from "@mui/material";
import {LinkItem} from "../types";
import {Link, useNavigate} from "react-router-dom";
import {createUseStyles} from "react-jss";
import {formatDate} from "date-fns";

const useStyles = createUseStyles({
  cardChips: {
    display: "flex",
    gap: "8px",
    flexWrap: "wrap", 
    lineHeight: "20px",
    alignItems: "center"
  },
  closedChip: {
    background: "var(--black)",
    color: "var(--white)"
  },
  truncateDescription: {
    textOverflow: "ellipsis", 
    overflow: "hidden", 
    display: "-webkit-box", 
    WebkitLineClamp: "2", 
    WebkitBoxOrient: "vertical"
  }
})

interface Props {
  linkTo: string
  logo?: string
  title: string
  text?: string
  infoChips?: string[]
  linkChips?: LinkItem[]
  closed?: string
  state?: string
}

export function ResultCard({ linkTo, logo, title, text, infoChips, linkChips, closed, state }: Props) {
  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const navigate = useNavigate()
  const classes = useStyles()

  const displayedLinkChips = (linkChips || []).slice(0, 2)

  return (

    <Link to={linkTo} className={closed && "card card-closed" || "card"} style={{ padding: isSm ? "20px" : "24px", flexDirection: isSm ? 'column' : undefined, gap: isSm ? "8px" : "20px" }}>
      <img src={logo || "/images/default-logo.png"} width={isSm ? "56px" : "72px"} height={isSm ? "56px" : "72px"} alt={title} style={{ borderRadius: "100%" }} />
      <Box>
        <Box display="flex" alignItems="baseline">
          <Typography variant="h4" mb={0}>{title}</Typography>
          {state && <Typography ml={1} variant="subtitle1" style={{ color: "var(--grey)" }}>{state}</Typography>}
        </Box>
        <Typography variant="body2" className={classes.truncateDescription}>{text}</Typography>
        <Box mt={1} className={classes.cardChips}>
          {closed && <Chip label={`Closed: ${formatDate(new Date(closed), "MMM yyyy")}`} className={classes.closedChip} />}
          {infoChips?.map((info, i) => <Chip key={i} label={info} />)}
          {displayedLinkChips?.map((item, i) => (
            <Box key={i} onClick={() => navigate(item.to)}>
              <Chip label={item.name} />
            </Box>
          ))}
          {displayedLinkChips.length < (linkChips || []).length && (
            <Typography variant="subtitle2">+{(linkChips || []).length - displayedLinkChips.length} more</Typography>
          )}
        </Box>
      </Box>
    </Link>

  )
}
