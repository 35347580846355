import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Routes} from './app-config';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme";
import {CssBaseline} from "@mui/material";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export function getEnv() {
    const domain = window.location.hostname.split(".")[0]
    if (domain === "localhost") return "local"
    else if (domain === "dev") return "dev"
    else if (domain === "demo") return "demo"
    return "prod"
}

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes />
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
